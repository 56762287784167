import React, { Component } from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';
import Input from '@material-ui/core/Input';
import Button from '@material-ui/core/Button';
import PersonIcon from '@material-ui/icons/Person';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import { authenticate } from './utils';

const styles = {
	form: {
		width: '100%',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center'
	},
	inputContainer: {
		width: '100%',
		display: 'flex',
		alignItems: 'center',
		padding: 10
	},
	icon: {
		color: '#ffffff'
	},
	input: {
		flexGrow: 2,
		marginLeft: 10,  
		color: '#113f63',
		backgroundColor: '#ffffff',
		'&:before': {
			borderBottom: '1px solid rgba(255, 255, 255, 0.6)',
		},
		'&:after': {
			borderBottom: '1px solid #0073d1',
		},
		'&:hover:not(.MuiInput-disabled-47):not(.MuiInput-focused-46):not(.MuiInput-error-49):before': {
			borderBottom: '1px solid rgba(255, 255, 255)',
		},
	},
	submit: {
		width: '70%',
		borderRadius: 1,
		marginTop: 10,
		color: '#ffffff',
		backgroundColor: '#0073d1',
		'&:hover': {
			backgroundColor: '#113f63'
		}
	},
	helperText: {
		'&:first-letter': {
			textTransform: 'capitalize'
		}
	}
};

class SignUp extends Component {

	constructor(props) {
		super(props);

		const messageElement = document.getElementsByName("message");

		this.state = {
			username: '',
			password: '',
			usernameError: null,
			passwordError: null,
			helperText: messageElement[0] ? messageElement[0].getAttribute("content") : null
		}
	}

	handleChange = (name, value) => {
		this.setState({ [name]: value, [name+'Error']: null });
	}

	handleChangeInput = name => event => {
		this.handleChange(name, event.target.value);
	}

	handleLogin = () => {
		const { username, password } = this.state;
		authenticate(username, password);
	}

	onSubmit = (event) => {
		event.preventDefault();

		let isValid = true;
		let unsetAttribute = [];
		if (this.state.username === '') {
			this.setState({ usernameError: true });
			unsetAttribute.push("username");
			isValid = false;
		}
		if (this.state.password === '') {
			this.setState({ passwordError: true });
			unsetAttribute.push("password");
			isValid = false;
		}

		if (isValid) {
			event.currentTarget.submit();
		} else {
			this.setState({ helperText: unsetAttribute.join(" and ") + " is required" })
		}
	}

	render() {
		const { classes } = this.props;

		const csrfName = document.getElementsByName("_csrf_name")[0].getAttribute("content");
		const csrfToken = document.getElementsByName("_csrf_token")[0].getAttribute("content");

		return (
			<form action="login" method="post" onSubmit={this.onSubmit} className={classes.form}>
				<div className={classes.inputContainer}>
					<PersonIcon className={classes.icon}/>
					<Input 
						id="username"
						name="username"
						autoFocus
						placeholder="Fullname" 
						value={this.state.username} 
						onChange={this.handleChangeInput("username")} 
						className={classes.input}
						inputProps={{
							style: { padding: 6 }
						}}
						error={this.state.usernameError !== null}
					/>
				</div>
				<div className={classes.inputContainer}>
					<PersonIcon className={classes.icon}/>
					<Input 
						id="username"
						name="username"
						autoFocus
						placeholder="Email" 
						value={this.state.username} 
						onChange={this.handleChangeInput("username")} 
						className={classes.input}
						inputProps={{
							style: { padding: 6 }
						}}
						error={this.state.usernameError !== null}
					/>
				</div>
				<div className={classes.inputContainer}>
					<PersonIcon className={classes.icon}/>
					<Input 
						id="username"
						name="username"
						autoFocus
						placeholder="Phone" 
						value={this.state.username} 
						onChange={this.handleChangeInput("username")} 
						className={classes.input}
						inputProps={{
							style: { padding: 6 }
						}}
						error={this.state.usernameError !== null}
					/>
				</div>
				<div className={classes.inputContainer}>
					<PersonIcon className={classes.icon}/>
					<Input 
						id="username"
						name="username"
						autoFocus
						placeholder="Username" 
						value={this.state.username} 
						onChange={this.handleChangeInput("username")} 
						className={classes.input}
						inputProps={{
							style: { padding: 6 }
						}}
						error={this.state.usernameError !== null}
					/>
				</div>
				<div className={classes.inputContainer}>
					<LockOpenIcon className={classes.icon}/>
					<Input 
						id="password"
						name="password"
						placeholder="Password" 
						type="password"
						value={this.state.password} 
						onChange={this.handleChangeInput("password")}
						className={classes.input}
						inputProps={{
							style: { padding: 6 }
						}}
						error={this.state.passwordError !== null}
					/>
				</div>
				<div className={classes.inputContainer}>
					<LockOpenIcon className={classes.icon}/>
					<Input 
						id="password"
						name="password"
						placeholder="Confirm Password" 
						type="password"
						value={this.state.password} 
						onChange={this.handleChangeInput("password")}
						className={classes.input}
						inputProps={{
							style: { padding: 6 }
						}}
						error={this.state.passwordError !== null}
					/>
				</div>
				<input type="hidden" name={csrfName} value={csrfToken} />
				<Button className={classes.submit} type="submit">Login</Button>
				<Typography variant='caption' color='error' align='center' className={classes.helperText}>
					{this.state.helperText}
				</Typography>
			</form>
		)
	}
}

export default withStyles(styles)(SignUp);